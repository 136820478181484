import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout.en";
import SEO from "../../components/seo";
import Masonry from "react-masonry-css";
import BackgroundImage from "gatsby-background-image";

const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
};

const CaseStudiesEN = ({ data }) => {
    const shortify = text => {
        const latinMap = {
            á: "a",
            à: "a",
            ã: "a",
            â: "a",
            ç: "c",
            é: "e",
            ê: "e",
            í: "i",
            ó: "o",
            õ: "o",
            ô: "o",
            ú: "u",
        };
        return text
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\-a-z0-9]/g, x => latinMap[x] || x)
            .replace(/[^\-a-z0-9]/g, "");
    };

    return (
        <Layout>
            <SEO title="Case Studies" description="Check our portfolio with examples of projects and implementation we did for our customers" lang="en" />

            <div style={{ position: "relative" }}>
                <BackgroundImage backgroundColor={`#040e18`} fluid={data.heroImage.childImageSharp.fluid} className="brook-breadcaump-area ptb--270 pt_md--190 pb_md--100 pt_sm--190 pb_sm--100 breadcaump-title-bar breadcaump-title-white" data-black-overlay="8" Tag="div">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcaump-inner text-center">
                                    <h1 className="heading heading-h1 text-white text-white line-height-1 font-80">Case Studies</h1>
                                    <div className="bkseparator--30"></div>
                                    <h5 className="heading heading-h5 text-white line-height-1-95">Check our portfolio with examples of projects and implementation we did for our customers.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
                <span className="unsplash-cred" style={{ opacity: 1, zIndex: 10 }}>
                    Photo by <a href="https://unsplash.com/@mediaprofile?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">MEDIA PROFILE</a> on{" "}
                    <a href="https://unsplash.com/s/photos/zoom-background-office?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
                </span>
            </div>

            <div className="brook-portfolio-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 bk-masonry-wrapper">
                <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="portfolio portfolio_style--1 portfolio-25 grid-width-2 cat--1 cat--4">
                    {data.wbapi.Cottonhat_casestudyList.map(item => {
                        return (
                            <div className="poss_relative">
                                <div className="thumb">
                                    {typeof item.photo !== "undefined" && typeof item.photo.url !== "undefined" && item.photo !== null && item.photo.url !== null ? (
                                        <img src={`${item.photo.url}?w=960&h=960&fit=crop`} alt={item.title.en_EN ? item.title.en_EN : item.title} />
                                    ) : (
                                        <img src={`/images/portfolio/grid/metro3-1.jpg`} alt="sample" />
                                    )}
                                </div>
                                <Link to={`/en/case-study/${item.title.en_EN ? shortify(item.title.en_EN) : item.uri}`}>
                                    <div className="port-overlay-info" style={{ backgroundColor: item.overlayColor }}>
                                        <div className="hover-action">
                                            <h3 className="post-overlay-title">
                                                <Link to={`/en/case-study/${item.title.en_EN ? shortify(item.title.en_EN) : item.uri}`}>{item.title.en_EN ? item.title.en_EN : item.title}</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </Masonry>
            </div>
        </Layout>
    );
};

export default CaseStudiesEN;

export const query1 = graphql`
    query {
        wbapi {
            Cottonhat_casestudyList(project: "592d4cf5b549b452a450d829") {
                project_id
                title {
                    en_EN
                    pt_PT
                }
                _id
                uri
                normalized_name
                overlayColor
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
        heroImage: file(relativePath: { eq: "cases.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
